.PrivacyPolicy {
    display: flex;
    // align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: auto;
    padding: 50px 5%;
    p {
        font-size: 17px;
        font-weight: 400;
        margin-top: 5px;
    }
    h3 {
        margin-top: 30px;
        font-weight: 600;
    }
    p strong:first-of-type {
        font-size: 22px;
        font-weight: 500;
    }
    ul {
        li {
            font-size: 18px;
            margin-top: 10px;
            font-weight: 400;
            b {
                font-weight: 500;
            }
        }
        li strong:first-of-type {
            font-size: 18px;
            margin-top: 10px;
            font-weight: 500;
        }
    }
}
@media (max-width: 800px) {
    .PrivacyPolicy {
        padding: 20px 5%;
    }
}
@media screen and (min-width: 1700px) {
    .PrivacyPolicy {
        padding: 20px 13%;
    }
}
