.BookAnAppointmentMainComponent {
    height: auto;
    overflow: hidden;
    background-color: #2a1768;
    // min-height: 100vh;
    *select,
    *option,
    *textarea,
    input {
        -webkit-appearance: none;
        background-color: white;
        font-size: 16px;
        font-family: Arial, Helvetica, sans-serif;
    }
    .BookAnAppointmentMainComponentTop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 60px 5% 0px 5%;

        .BookAnAppointmentMainComponentTopLeft {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            h1 {
                color: white;
                font-size: 30px;
                font-weight: 500;
                // transform: scale(0.9, 1);
                // background-color: red;
            }
            h2 {
                color: white;
                font-size: 16px;
                font-weight: 300;
                margin-top: 10px;
                // transform: scale(0.9, 1);
                // background-color: red;
            }
        }
    }
    .BookAnAppointmentMainComponentTopMain {
        padding: 20px 5% 20px 5%;
        .BookAnAppointmentMainComponentMaps {
            display: flex;
            align-items: center;
            height: auto;
            width: 100%;
            margin-top: 20px;
            h4 {
                padding: 15px 20px 20px 20px;
                color: white;
                font-size: 20px;
                font-weight: 400;
                width: 160px;
                cursor: pointer;
                text-wrap: nowrap;
                // padding-top: 20px;
            }
        }
        .BookAnAppointmentMainComponentPage1,
        .BookAnAppointmentMainComponentPage3 {
            background-color: white;
            padding: 20px 20px 40px 20px;
            border-radius: 10px;
            .BookAnAppointmentMainRightFullName,
            .BookAnAppointmentMainRightPhoneAndMaill,
            .BookAnAppointmentMainRightServices,
            .BookAnAppointmentMainRightDateAndTime {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;
                .BookAnAppointmentMainRightBox {
                    width: 100%;
                    h4 {
                        color: #1c1e21;
                        font-size: 16px;
                        font-weight: 500;
                        margin-top: 20px;
                        span {
                            color: red;
                        }
                    }
                    h3 {
                        color: #040404;
                        font-size: 15px;
                        font-weight: 400;
                        margin-top: 10px;
                        background-color: #da291c1d;
                        padding: 7px;
                        border-radius: 5px;
                        span {
                            color: red;
                        }
                    }
                    input,
                    select {
                        padding: 0px 10px;
                        margin-top: 10px;
                        width: 100%;
                        min-height: 50px;
                        outline: none;
                        border: 1px solid #6f6f6f61;
                        border-radius: 5px;
                    }
                    input:focus {
                        border: 1px solid #6f6f6f61;
                        outline: 1px solid #2a1768;
                    }
                }
            }
            button {
                background-color: #da291c;
                padding: 20px 20px;
                border-radius: 5px;
                color: white;
                margin-right: 30px;
                cursor: pointer;
                text-decoration: none;
                font-size: 18px;
                outline: none;
                border: none;
                width: 100%;
                margin-top: 20px;
            }
        }
        .BookAnAppointmentMainComponentPage2 {
            background-color: white;
            padding: 20px 20px 40px 20px;
            border-radius: 10px;
            .BookAnAppointmentMainRightAddress1,
            .BookAnAppointmentMainRightAddress2,
            .BookAnAppointmentMainRightAddress3 {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;
                .BookAnAppointmentMainRightBox {
                    width: 100%;
                    h4 {
                        color: #1c1e21;
                        font-size: 16px;
                        font-weight: 500;
                        margin-top: 20px;
                        span {
                            color: red;
                        }
                    }
                    input,
                    select {
                        padding: 0px 10px;
                        margin-top: 10px;
                        width: 100%;
                        min-height: 50px;
                        outline: none;
                        border: 1px solid #6f6f6f61;
                        border-radius: 5px;
                    }
                    input:focus {
                        border: 1px solid #6f6f6f61;
                        outline: 1px solid #2a1768;
                    }
                }
            }
            .BookAnAppointmentMainComponentButtons {
                display: flex;
                gap: 20px;
                button:first-child {
                    background-color: #0035534a;
                    color: black;
                }
                button {
                    background-color: #da291c;
                    padding: 20px 20px;
                    border-radius: 5px;
                    color: white;
                    // margin-right: 30px;
                    cursor: pointer;
                    text-decoration: none;
                    font-size: 18px;
                    outline: none;
                    border: none;
                    width: 100%;
                    margin-top: 20px;
                }
            }
        }
        .BookAnAppointmentMainComponentPage3 {
            background-color: white;
            padding: 20px 20px 40px 20px;
            border-radius: 10px;
            .BookAnAppointmentMainRightNote {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;
                .BookAnAppointmentMainRightBox {
                    width: 100%;
                    h4 {
                        color: #1c1e21;
                        font-size: 16px;
                        font-weight: 500;
                        margin-top: 20px;
                        span {
                            color: red;
                        }
                    }
                    textarea {
                        padding: 10px 10px;
                        margin-top: 10px;
                        width: 100%;
                        min-height: 150px;
                        outline: none;
                        border: 1px solid #6f6f6f61;
                        border-radius: 5px;
                        resize: none;
                    }
                    input:focus {
                        border: 1px solid #6f6f6f61;
                        outline: 1px solid #2a1768;
                    }
                }
            }
            .BookAnAppointmentMainComponentButtons {
                display: flex;
                gap: 20px;
                button:first-child {
                    background-color: #0035534a;
                    color: black;
                }
                button:last-child {
                    margin-left: 20px;
                }
                form {
                    width: 160%;
                    margin-left: 20px;
                }
                button,
                input {
                    background-color: #da291c;
                    padding: 20px 20px;
                    border-radius: 5px;
                    color: white;
                    cursor: pointer;
                    text-decoration: none;
                    font-size: 18px;
                    outline: none;
                    border: none;
                    width: 100%;
                    margin-top: 20px;
                    i {
                        margin-right: 10px;
                    }
                }
            }
        }
        .BookAnAppointmentMainComponentEmergery {
            display: flex;
            align-items: flex-start;
            justify-content: start;
            flex-direction: column;

            .BookAnAppointmentMainComponentEmergeryBox {
                display: flex;
                align-items: center;
                justify-content: start;
                background-color: #1382d72b;
                padding: 12px 20px;
                border-radius: 8px;
                margin-top: 20px;

                a {
                    color: #ffffff;
                    font-size: 20px;
                    font-weight: 600;
                    text-decoration: underline;
                }
            }
            .BookAnAppointmentMainComponentRates {
                margin-top: 20px;
                border-radius: 10px;
                margin-top: 10px;
                padding: 20px;
                background-color: #1382d72b;
                overflow: auto;
                width: 100%;
                .BookAnAppointmentMainComponentRatesImages {
                    display: flex;
                    img {
                        width: auto;
                        height: 80px;
                        object-fit: cover;
                        margin-right: 20px;
                        background-color: rgb(255, 255, 255);
                        padding: 7px;
                        border-radius: 5px;
                    }
                }
                h1 {
                    color: #ffffff;
                    font-size: 20px;
                    font-weight: 600;
                    margin-top: 20px;
                }
                h2 {
                    font-size: 20px;
                    font-weight: 600;
                    margin-top: 10px;
                }
                h3 {
                    color: #ffffff;
                    font-size: 17px;
                    font-weight: 400;
                    margin-top: 20px;
                }
            }
        }
        .BookAnAppointmentMainComponentThanks {
            padding: 40px 0px;
            h3 {
                text-align: center;
                font-weight: 400;
            }
            h4 {
                display: flex;
                align-items: center;
                flex-direction: column;
                margin-top: 10px;
                color: #5d5d5d;
                font-weight: 400;
                h5 {
                    cursor: pointer;
                    color: #2a1768;
                    border: 1px solid #919191;
                    padding: 10px 20px;
                    border-radius: 2px;
                    margin-top: 15px;
                }
            }
        }
        .BookAnAppointmentMainComponentContent {
            margin-top: 50px;
            p {
                color: #1c1e21;
                font-size: 18px;
                font-weight: 300;
                margin-top: 20px;
            }
            p strong {
                color: #1c1e21;
                font-size: 20px;
                font-weight: 600;
                margin-top: 20px;
            }
        }
    }
    // HomePageGoogleFooter
    .HomePageGoogleFooter {
        .HomePageGoogleFooterTop {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 60px 5% 0px 5%;
            margin-bottom: -40px;
            background-color: white;
            .HomePageGoogleFooterTopTitle {
                display: flex;
                align-items: center;
                justify-content: center;
                // justify-content: space-between;
                h1 {
                    font-size: 30px;
                    font-weight: 600;
                    color: #2a1768;
                    text-align: center;
                    font-style: oblique 50deg;
                    margin: 0px 20px;
                }
                img {
                    width: 40px;
                }
            }
            .HomePageGoogleFooterTopImg {
                img {
                    width: 100px;
                    margin-top: 20px;
                }
            }
        }
        .HomePageGoogleFooterBottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 60px 5% 0px 5%;
            background-color: #eceef6;

            .HomePageGoogleFooterBottomTop {
                padding-right: 100px;
                width: 50%;

                img {
                    width: 220px;
                    height: 100px;
                }
                p {
                    margin-top: 20px;
                    color: #000000;
                }
                .SiteMapDiv {
                    h1 {
                        margin-top: 20px;
                        color: #000000;
                        font-size: 20px;
                        font-weight: 500;
                        margin-bottom: 10px;
                    }
                    a {
                        font-size: 20px;
                        color: #000000;
                        text-decoration: underline;
                        margin-right: 10px;
                    }
                }
            }
            .HomePageGoogleFooterMiddle {
                width: 20%;
                .HomePageGoogleLocationLeftBox {
                    margin-top: 10px;
                    h4 {
                        font-size: 20px;
                        font-weight: 600;
                        color: #2a1768;
                        text-align: start;
                    }
                    h5 {
                        font-size: 18px;
                        font-weight: 500;
                        color: #09031f;
                        text-align: start;
                        margin-top: 5px;
                        margin-bottom: 5px;
                    }
                    a {
                        font-size: 20px;
                        font-weight: 400;
                        color: #09031f;
                        text-align: start;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }

            .HomePageGoogleFooterBottomBottom {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 15px;
                    img {
                        width: 30px;
                        margin-right: 15px;
                        // background-color: white;
                        border-radius: 5%;
                        padding: 7px;
                        outline: 2px solid white;
                    }
                    a {
                        font-size: 15px;
                        font-weight: 500;
                        // text-decoration: underline;
                        color: black;
                    }
                }
            }
        }
    }
    // MainBanner2Top2
    .MainBanner2Top2 {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        position: relative;
        z-index: 2;

        .MainBanner2TopBox {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            width: 100%;
            position: relative;
            overflow: hidden;

            .MainBanner2TopBoxBack {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                min-height: 100%;
                background-color: #2a1768;
                z-index: 0;
                width: 100%;
                background: linear-gradient(to right bottom, #2a1768 50%, #eceef6 50.3%);
            }
            .MainBanner2TopBoxMain {
                padding: 0px 5% 50px 5%;
                z-index: 2;

                .MainBanner2TopBoxMenu {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 40px;
                    gap: 30px;
                    .MainBanner2TopBoxBox {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: space-between;
                        background-color: white;
                        border-radius: 2px;
                        padding: 25px 10px;
                        width: 25%;
                        min-height: 220px;
                        position: relative;
                        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                        margin-top: 20px;
                        border-radius: 8px;
                        img {
                            position: absolute;
                            width: 90px;
                            padding: 8px;
                            border: 3px solid #2a1768;
                            border-radius: 50%;
                            top: -45px;
                            background-color: white;
                        }
                        h3 {
                            text-align: center;
                            font-size: 24px;
                            font-weight: 700;
                            color: rgb(61, 61, 61);
                            margin-top: 40px;
                        }
                        h2 {
                            text-align: center;
                            font-size: 16px;
                            font-weight: 400;
                            color: rgb(61, 61, 61);
                            margin-top: 20px;
                        }
                        h4 {
                            text-align: center;
                            font-size: 15px;
                            font-weight: 500;
                            color: #2a1768;
                            margin-top: 0px;
                        }
                    }
                    .MainBanner2TopBoxBox:hover {
                        background-color: rgb(242, 242, 242);
                        border-radius: 8px;
                    }
                    .MainBanner2TopBoxBox:last-child {
                        border-right: none;
                    }
                }
                .MainBanner2TopBoxMainChat {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: white;
                    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                    border-radius: 8px;
                    padding: 20px 20px;
                    margin-top: 20px;
                    img {
                        width: 90px;
                    }
                    .MainBanner2TopBoxMainChatText {
                        padding-left: 30px;
                        h4 {
                            font-size: 24px;
                            font-weight: 700;
                            color: rgb(61, 61, 61);
                            text-transform: capitalize;
                            display: inline-block;
                            border-bottom: 2px solid #2a1768;
                        }
                        h3 {
                            font-size: 18px;
                            font-weight: 400;
                            color: rgb(61, 61, 61);
                            margin-top: 10px;
                        }
                        h5 {
                            font-size: 18px;
                            font-weight: 400;
                            color: #3d3d3d;
                            margin-top: 10px;
                            border-left: 3px solid #3d3d3d;
                            padding-left: 5px;
                            a {
                                color: #2a1768;
                                text-decoration: underline;
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 1700px) {
    .BookAnAppointmentMainComponent {
        .BookAnAppointmentMainComponentTopMain,
        .BookAnAppointmentMainComponentTop {
            padding-right: 13%;
            padding-left: 13%;
        }
        .HomePageGoogleFooter {
            .HomePageGoogleFooterTop,
            .HomePageGoogleFooterBottom {
                padding-right: 13%;
                padding-left: 13%;
            }
        }
        .MainBanner2Top2 {
            .MainBanner2TopBox {
                .MainBanner2TopBoxMain {
                    padding: 50px 13%;
                }
            }
        }
    }
}
@media screen and (max-width: 800px) {
    .BookAnAppointmentMainComponent {
        // top: 0px;
        max-height: calc(100%);
        min-height: calc(100%);
        .BookAnAppointmentMainComponentTop {
            padding: 30px 5% 0px 5%;

            .BookAnAppointmentMainComponentTopLeft {
                h1 {
                    font-size: 22px;
                    font-weight: 500;
                    // transform: scale(0.9, 1);
                    // background-color: red;
                }
                h2 {
                    font-size: 14px;
                    font-weight: 300;
                    margin-top: 10px;
                    // transform: scale(0.9, 1);
                    // background-color: red;
                }
                img {
                    width: 30px;
                    height: 30px;
                }
            }
            .BookAnAppointmentMainComponentTopRigt {
                img {
                    width: 100px;
                    height: 100px;
                    // background-color: red;
                }
            }
        }
        .BookAnAppointmentMainComponentTopMain {
            padding: 0px 5% 40px 5%;
            .BookAnAppointmentMainComponentMaps {
                h4 {
                    padding: 10px 10px;
                    font-size: 12px;
                }
            }
            .BookAnAppointmentMainComponentPage1 {
                .BookAnAppointmentMainRightFullName,
                .BookAnAppointmentMainRightPhoneAndMaill,
                .BookAnAppointmentMainRightServices,
                .BookAnAppointmentMainRightDateAndTime {
                    gap: 10px;
                    .BookAnAppointmentMainRightBox {
                        h4 {
                            font-size: 12px;
                            margin-top: 10px;
                        }
                        h3 {
                            font-size: 12px;
                        }
                    }
                }
                .BookAnAppointmentMainComponentButtons {
                    background-color: rgb(255, 255, 255);

                    button {
                        padding: 20px 20px;
                        border-radius: 5px;
                        color: white;
                        margin-right: 30px;
                        cursor: pointer;
                        text-decoration: none;
                        font-size: 18px;
                        outline: none;
                        border: none;
                        width: 100%;
                        margin-top: 20px;
                    }
                }
            }
            .BookAnAppointmentMainComponentPage2 {
                .BookAnAppointmentMainRightAddress1,
                .BookAnAppointmentMainRightAddress2,
                .BookAnAppointmentMainRightAddress3 {
                    gap: 10px;
                    .BookAnAppointmentMainRightBox {
                        h4 {
                            font-size: 12px;
                            margin-top: 10px;
                        }
                    }
                }
                .BookAnAppointmentMainComponentButtons {
                    display: flex;
                    gap: 20px;
                    background-color: rgb(255, 255, 255);
                    button:first-child {
                    }
                    button {
                        padding: 20px 20px;
                        border-radius: 5px;
                        color: white;
                        // margin-right: 30px;
                        cursor: pointer;
                        text-decoration: none;
                        font-size: 18px;
                        outline: none;
                        border: none;
                        width: 100%;
                        margin-top: 20px;
                    }
                }
            }
            .BookAnAppointmentMainComponentPage3 {
                .BookAnAppointmentMainRightNote {
                    gap: 10px;
                    .BookAnAppointmentMainRightBox {
                        h4 {
                            font-size: 12px;
                            margin-top: 10px;
                        }
                    }
                }
                .BookAnAppointmentMainComponentButtons {
                    display: flex;
                    gap: 0px;
                    background-color: rgb(255, 255, 255);
                    button:first-child {
                    }
                    button {
                        padding: 20px 20px;
                        border-radius: 5px;
                        color: white;
                        // margin-right: 30px;
                        cursor: pointer;
                        text-decoration: none;
                        font-size: 18px;
                        outline: none;
                        border: none;
                        width: 100%;
                        margin-top: 20px;
                    }
                }
            }
            .BookAnAppointmentMainComponentEmergery {
                width: 100%;
                .BookAnAppointmentMainComponentEmergeryBox {
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    background-color: #1382d72b;
                    padding: 12px 20px;
                    border-radius: 8px;
                    margin-top: 20px;

                    a {
                        color: #ffffff;
                        font-size: 20px;
                        font-weight: 600;
                        text-decoration: underline;
                    }
                }
                .BookAnAppointmentMainComponentRates {
                    margin-top: 20px;
                    border-radius: 10px;
                    margin-top: 10px;
                    padding: 20px;
                    background-color: #1382d72b;
                    width: 100%;
                    padding-top: 0px;

                    .BookAnAppointmentMainComponentRatesImages {
                        display: flex;
                        width: 100%;
                        // flex-direction: column;
                        overflow: auto;
                        padding-bottom: 10px;
                        img {
                            width: auto;
                            height: 60px;
                            object-fit: cover;
                            margin-right: 20px;
                            margin-top: 20px;
                        }
                    }
                    h1 {
                        color: #ffffff;
                        font-size: 20px;
                        font-weight: 600;
                        margin-top: 20px;
                    }
                    h2 {
                        font-size: 20px;
                        font-weight: 600;
                        margin-top: 5px;
                    }
                    h3 {
                        color: #ffffff;
                        font-size: 13px;
                        font-weight: 400;
                        margin-top: 10px;
                    }
                }
            }
        }

        .HomePageGoogleFooter {
            .HomePageGoogleFooterTop {
                .HomePageGoogleFooterTopTitle {
                    h1 {
                        font-size: 28px;
                        margin: 0px 6px;
                    }
                }
            }
            .HomePageGoogleFooterBottom {
                flex-direction: column-reverse;
                .HomePageGoogleFooterBottomTop {
                    padding-right: 0px;
                    margin-top: 50px;
                    width: 100%;

                    p {
                        font-size: 12px;
                    }
                }
                .HomePageGoogleFooterMiddle {
                    width: 100%;
                    margin-top: 40px;
                }
                .HomePageGoogleFooterBottomBottom {
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    span {
                        img {
                            width: 20px;
                            padding: 4px;
                        }
                    }
                }
            }
        }
        .MainBanner2Top2 {
            .MainBanner2TopBox {
                // padding: 20px 5% 20px 5%;
                .MainBanner2TopBoxMain {
                    padding: 50px 5%;

                    h2 {
                        text-align: start;
                        font-size: 28px;
                    }
                    .MainBanner2TopBoxMenu {
                        flex-direction: column;
                        .MainBanner2TopBoxBox {
                            width: 100%;
                            height: auto;
                            justify-content: start;
                            padding: 10px 10px;
                            margin-bottom: 10px;
                            min-height: 200px;

                            img {
                                width: 80px;
                            }
                        }
                    }
                    .MainBanner2TopBoxMainChat {
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        padding: 0px 20px 20px 20px;
                        margin-top: 70px;
                        img {
                            // border: 2px solid #2a1768;
                            // border-radius: 50%;
                            // padding: 5px;
                            margin-top: -70px;
                            width: 110px;
                        }
                        .MainBanner2TopBoxMainChatText {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding-left: 0px;
                            h4,
                            h3 {
                                // width: 100%;
                                text-align: center;
                            }
                            h4 {
                                font-size: 21px;
                                margin-top: 15px;
                            }
                            h3 {
                                font-size: 16px;
                            }
                            h5 {
                                border-left: 0px;
                                text-align: center;
                                font-size: 16px;

                                a {
                                    margin-top: 20px;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
