.HomePageGoogle {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: auto;

    // MainBanner
    .MainBanner {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        flex-direction: row-reverse;
        min-height: 65vh;
        min-height: 600px;
        min-width: 100vw;
        max-height: 65vh;
        background-color: #eceef6;
        padding: 100px 5% 0px 5%;
        overflow: hidden;
        .MainBannerLeft {
            display: flex;
            ruby-align: center;
            flex-direction: column;
            width: 50%;
            padding-left: 30px;
            margin-top: -100px;
            h1 {
                font-size: 30px;
                font-weight: 700;
                color: #2a1768;
            }
            h2 {
                font-size: 25px;
                font-weight: 400;
                color: #2a1768;
                margin-top: 15px;
                margin-bottom: 8px;
                text-transform: capitalize;
                b {
                    color: #da291c;
                }
                span {
                    font-weight: 600;
                }
            }
            h3 {
                display: flex;
                align-items: center;
                font-size: 22px;
                font-weight: 600;
                color: #2a1768;
                margin-top: 15px;
                img {
                    width: 20px;
                    margin-right: 10px;
                }
            }
            .MainBannerLeftButtons {
                display: flex;
                flex-direction: column;
                a {
                    align-items: center;
                    justify-content: space-between;
                    font-size: 32px;
                    font-weight: 700;
                    color: #da291c;
                    cursor: pointer;
                    border-radius: 3px;
                    cursor: pointer;
                    margin-top: 20px;
                }
                a:hover {
                    text-decoration: underline;
                }
                a:nth-child(2) {
                    margin-top: 0px;
                    color: #2a1768;
                    font-size: 25px;
                }
                a:last-child {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 300px;
                    background-color: #2a1768;
                    color: white;
                    font-size: 25px;
                    padding: 18px 0px;
                    font-weight: 500;

                    img {
                        height: 25px;
                        width: 25px;
                        margin-left: 10px;
                        filter: invert(1);
                    }
                }
            }
        }
        .MainBannerRight {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            height: 100%;
            position: relative;
            img {
                position: absolute;
                bottom: -150px;
                // margin-bottom: 100px;
                // margin-right: 50px;
                // width: 600px;
                width: 500px;
                margin-right: 90px;
                z-index: 3;
                border-radius: 0px;
            }
            .MainBannerRightBubble1 {
                // display: none;
                position: absolute;
                right: 290px;
                bottom: -200px;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-color: #ffffff;
                opacity: 0.8;
                z-index: 2;
                box-shadow: 0px 10px 15px -3px rgba(255, 255, 255, 0.54);
            }
            .MainBannerRightBubble2 {
                // display: none;
                position: absolute;
                right: 50px;
                bottom: -150px;
                width: 90px;
                height: 90px;
                border-radius: 50%;
                opacity: 0.5;
                background-color: #ffffff;
                z-index: 2;
                box-shadow: 0px 10px 15px -3px rgba(255, 255, 255, 0.54);
            }
        }
    }
    // BookAnAppointmentMainComponentEmergery
    .BookAnAppointmentMainComponentEmergery {
        display: flex;
        align-items: flex-start;
        justify-content: start;
        flex-direction: column;
        background-color: #2a1768;
        .BookAnAppointmentMainComponentRates {
            border-radius: 10px;
            width: calc(100% - 10%);
            padding: 20px;
            margin: 20px 5% 0px 5%;
            background-color: #1382d72b;
            height: auto;
            overflow: auto;
            .GoogleButt {
                display: flex;
                flex-direction: column;
                h6 {
                    font-size: 20px;
                    font-weight: 600;
                    color: white;
                    padding-bottom: 20px;
                }
            }
            .BookAnAppointmentMainComponentRatesImages {
                display: flex;
                img {
                    width: auto;
                    height: 80px;
                    object-fit: cover;
                    margin-right: 20px;
                    background-color: rgb(255, 255, 255);
                    padding: 7px;
                    border-radius: 5px;
                }
            }
            h1 {
                color: #ffffff;
                font-size: 20px;
                font-weight: 600;
                margin-top: 20px;
            }
            h2 {
                font-size: 20px;
                font-weight: 600;
                margin-top: 10px;
            }
            h3 {
                color: #ffffff;
                font-size: 17px;
                font-weight: 400;
                margin-top: 12px;
                a {
                    color: white;
                    text-decoration: underline;
                    margin-top: 5px;
                    font-size: 18px;
                    padding-top: 20px;
                    cursor: pointer;
                }
            }
        }
        .BookAnAppointmentMainComponentEmergeryBox {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #da291c;
            padding: 12px 20px;
            margin-top: 30px;
            width: 100%;

            a {
                color: #ffffff;
                font-size: 20px;
                font-weight: 600;
                text-decoration: underline;
            }
        }
    }
    // MainBanner2Top2
    .MainBanner2Top2 {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        position: relative;
        z-index: 2;

        .MainBanner2TopBox {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            width: 100%;
            position: relative;
            overflow: hidden;

            .MainBanner2TopBoxBack {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                min-height: 100%;
                background-color: #2a1768;
                z-index: 0;
                width: 100%;
                background: linear-gradient(to right bottom, #2a1768 50%, #eceef6 50.3%);
            }
            .MainBanner2TopBoxMain {
                padding: 50px 5%;
                z-index: 1;
                h2 {
                    text-align: start;
                    font-size: 40px;
                    font-weight: 500;
                    color: white;
                }
                .MainBanner2TopBoxMenu {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 50px;
                    gap: 30px;
                    .MainBanner2TopBoxBox {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: space-between;
                        background-color: white;
                        border-radius: 2px;
                        padding: 25px 10px;
                        width: 25%;
                        min-height: 220px;
                        position: relative;
                        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                        margin-top: 20px;
                        border-radius: 8px;
                        img {
                            position: absolute;
                            width: 90px;
                            padding: 8px;
                            border: 3px solid #2a1768;
                            border-radius: 50%;
                            top: -45px;
                            background-color: white;
                        }
                        h3 {
                            text-align: center;
                            font-size: 24px;
                            font-weight: 700;
                            color: rgb(61, 61, 61);
                            margin-top: 40px;
                        }
                        h2 {
                            text-align: center;
                            font-size: 16px;
                            font-weight: 400;
                            color: rgb(61, 61, 61);
                            margin-top: 20px;
                        }
                        h4 {
                            text-align: center;
                            font-size: 15px;
                            font-weight: 500;
                            color: #2a1768;
                            margin-top: 0px;
                        }
                    }
                    .MainBanner2TopBoxBox:hover {
                        background-color: rgb(242, 242, 242);
                        border-radius: 8px;
                    }
                    .MainBanner2TopBoxBox:last-child {
                        border-right: none;
                    }
                }
                .MainBanner2TopBoxMainChat {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: white;
                    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                    border-radius: 8px;
                    padding: 20px 20px;
                    margin-top: 20px;
                    img {
                        width: 90px;
                    }
                    .MainBanner2TopBoxMainChatText {
                        padding-left: 30px;
                        h4 {
                            font-size: 24px;
                            font-weight: 700;
                            color: rgb(61, 61, 61);
                            text-transform: capitalize;
                            display: inline-block;
                            border-bottom: 2px solid #2a1768;
                        }
                        h3 {
                            font-size: 18px;
                            font-weight: 400;
                            color: rgb(61, 61, 61);
                            margin-top: 10px;
                        }
                        h5 {
                            font-size: 18px;
                            font-weight: 400;
                            color: #3d3d3d;
                            margin-top: 10px;
                            border-left: 3px solid #3d3d3d;
                            padding-left: 5px;
                            a {
                                color: #2a1768;
                                text-decoration: underline;
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    // MainBanner2
    .MainBanner2 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 50px 5%;
        background-color: white;
        .MainBanner2TopTop {
            display: flex;
            flex-direction: row-reverse;
            height: auto;
            width: 100%;
            // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

            .MainBanner2TopTopLeft {
                width: 50%;
                height: 100%;
                padding: 8px;
                margin-left: 190px;
                background-color: white;
                border-radius: 8px;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    object-fit: cover;
                    object-position: top;
                }
            }
            .MainBanner2TopTopRight {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 50%;
                height: 100%;
                background-color: white;
                border-radius: 0px 8px 8px 0px;
                h4 {
                    font-size: 50px;
                    font-weight: 700;
                    color: #2a1768;
                }
                h5 {
                    font-size: 20px;
                    font-weight: 500;
                    color: #242424;
                    margin-top: 10px;
                }
                .MainBanner2TopBox {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-direction: column;
                    background-color: white;
                    margin-top: 15px;
                    margin-top: 20px;

                    h2 {
                        text-align: center;
                        font-size: 25px;
                        font-weight: 600;
                        color: #2a1768;
                        // text-decoration: underline;
                        border-bottom: 2px solid #da291c;
                    }
                    h3 {
                        text-align: start;
                        font-size: 18px;
                        font-weight: 400;
                        color: #242424;
                        margin-top: 5px;
                    }
                }
                .MainBanner2TopBox:last-child {
                    border-bottom: none;
                }
            }
        }

        .MainBanner2TopTopBtn {
            display: flex;
            margin-top: 40px;
            background-color: #eceef6;
            border-left: 4px solid #2a1768;
            padding: 20px 30px;
            box-shadow: rgba(0, 0, 0, 0.163) 0px 5px 19px 0px;

            .MainBanner2TopTopBtnLeft {
                display: none;
                align-items: center;
                justify-content: space-between;
                width: 60%;
                padding-right: 60px;
                img {
                    width: 70px;
                    margin-right: 20px;
                }
                span {
                    h1 {
                        font-size: 40px;
                        font-weight: 700;
                        color: #2a1768;
                    }
                    h2 {
                        font-size: 18px;
                        font-weight: 400;
                        color: #242424;
                        margin-top: 10px;
                    }
                }
            }
            .MainBanner2TopTopBtnRight {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                // justify-content: space-between;
                // width: 40%;
                gap: 20px;
                a {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 23px;
                    font-weight: 500;
                    color: WHITE;
                    white-space: nowrap;
                    max-width: 400px;
                    width: 100%;

                    cursor: pointer;
                    background-color: #2a1768;
                    border-radius: 3px;
                    cursor: pointer;
                    padding: 20px 40px;
                    padding-left: 20px;
                    margin-right: 0px;
                    // margin-top: 20px;

                    img {
                        // background-color: white;
                        height: 20px;
                        width: 20px;
                        margin-left: 10px;
                        filter: invert(1);
                    }
                }
                a:hover {
                    text-decoration: underline;
                }
                a:last-child {
                    background-color: #da291c;
                    color: white;
                    img {
                        filter: invert(1);
                    }
                }
            }
        }
    }
    // MainBanner3
    .MainBanner3 {
        padding: 20px 0%;
        background-color: white;
        .MainBanner2Bottom {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
            height: auto;

            .MainBanner2BottomBox {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 40px 5%;
                border-radius: 10px;
                width: 100%;
                height: auto;
                min-height: auto;
                margin-top: 40px;
                background-color: #eceef6;
                .MainBanner2BottomBoxLeft {
                    width: 50%;
                    padding-right: 50px;
                    h2 {
                        font-size: 50px;
                        font-weight: 700;
                        color: #2a1768;
                    }
                    h3 {
                        font-size: 17px;
                        font-weight: 400;
                        color: #242424;
                        margin-top: 10px;
                    }
                    a {
                        color: #2a1768;
                        font-size: 30px;
                        font-weight: 500;
                        cursor: pointer;
                        // background-color: #2a1768;
                        border-radius: 3px;
                        cursor: pointer;
                        margin-top: 20px;
                        text-decoration: underline;
                    }
                }
                .MainBanner2BottomBoxRight {
                    width: 50%;
                    img {
                        width: 100%;
                        height: 250px;
                        object-fit: cover;
                    }
                }
            }
            .MainBanner2BottomBox:last-child {
                flex-direction: row-reverse;
                .MainBanner2BottomBoxRight {
                    padding-right: 60px;
                }
            }
        }
    }
    // MainBanner5q
    .MainBanner5 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        min-height: auto;
        min-width: 100vw;
        // background-color: #97d7f01c;
        padding: 80px 5% 120px 5%;
        .MainBanner5Left {
            display: flex;
            flex-direction: column;
            width: 70%;
            padding-right: 20px;
            h1 {
                font-size: 33px;
                font-weight: 600;
                color: #2a1768;
            }
            .MainBanner5LeftBox {
                display: flex;
                align-items: center;
                margin-top: 35px;
                h2 {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 33px;
                    font-weight: 800;
                    color: #2a1768;
                    background-color: #0156ff1c;
                    border-radius: 50%;
                    min-width: 50px;
                    min-height: 50px;
                    margin-right: 20px;
                }
                .MainBanner5LeftBoxRigth {
                    b {
                        text-decoration: underline;
                        color: #000000;
                    }
                    h3 {
                        font-size: 23px;
                        font-weight: 700;
                        color: #2a1768;
                    }
                    h4 {
                        font-size: 17px;
                        font-weight: 400;
                        color: rgb(48, 48, 48);
                        margin-top: 10px;
                    }
                }
            }
            .MainBanner5LeftAbutton {
                margin-top: 30px;
                a {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 23px;
                    font-weight: 500;
                    color: WHITE;
                    white-space: nowrap;
                    max-width: 400px;
                    width: 100%;
                    cursor: pointer;
                    background-color: #2a1768;
                    border-radius: 3px;
                    cursor: pointer;
                    padding: 20px 40px;
                    padding-left: 20px;
                    margin-right: 0px;
                    margin-top: 20px;
                    // margin-top: 20px;

                    img {
                        // background-color: white;
                        height: 20px;
                        width: 20px;
                        margin-left: 10px;
                        filter: invert(1);
                    }
                }
                a:hover {
                    text-decoration: underline;
                }
                a:last-child {
                    background-color: #da291c;
                    color: white;
                    img {
                        filter: invert(1);
                    }
                }
            }
        }
        .MainBanner5Right {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30%;
            position: relative;
            img {
                // max-width: 250px;
                height: 400px;
                object-fit: cover;
                border-radius: 0% 10% 10% 0px;
                z-index: 2;
            }
            .MainBanner5RightBubble1 {
                position: absolute;
                left: -20px;
                bottom: 100px;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background-color: #2a1768a0;
                z-index: 1;
            }
            .MainBanner5RightBubble2 {
                position: absolute;
                right: 0px;
                bottom: -100px;
                width: 150px;
                height: 150px;
                border-radius: 50%;
                background-color: #da291c85;
                z-index: 3;
            }
        }
    }
    // MainBanner6
    .MainBanner6 {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 40px 5% 0px 5%;
        width: 100%;
        margin-top: 0px;
        height: auto;
        background-color: #eceef6;
        .MainBanner6Left {
            width: 50%;
            padding-right: 50px;
            h2 {
                font-size: 50px;
                font-weight: 700;
                color: #2a1768;
            }
            h3 {
                font-size: 17px;
                font-weight: 400;
                color: #242424;
                margin-top: 10px;
                margin-bottom: 50px;
            }
            .MainBanner6LeftContact {
                color: white;
                font-size: 20px;
                font-weight: 500;
                border-radius: 3px;
                cursor: pointer;
                text-decoration: underline;
                background-color: #2a1768;
                padding: 20px;
            }
            .MainBanner4FaqMore {
                // background-color: #2a1768;
                // padding: 20px;
                margin: 50px 0px;
                border-radius: 5px;
                a {
                    color: #2a1768;
                    font-weight: 500;
                    text-decoration: underline;
                }
                .row-title-text,
                .styles_row-title-text__1MuhU {
                    font-weight: 600;
                }
                .faq-row,
                .styles_faq-row__2YF3c {
                    border-bottom: 2px solid #2a1768;
                }
            }
        }
        .MainBanner6Right {
            display: flex;
            align-items: end;
            width: 50%;
            height: 100%;
            img {
                width: 70%;
                margin-left: 100px;
            }
        }
    }
    // MainBanner7
    .MainBanner7 {
        width: 100%;
        padding: 60px 5%;
        background-color: #eceef631;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h1 {
            width: 100%;
            font-size: 40px;
            font-weight: 700;
            color: #2a1768;
            text-align: start;
        }
        .LocationReviewsLsit {
            width: 100%;
            display: flex;
            overflow: scroll;
            overflow-y: hidden;
            position: relative;
            margin-top: 30px;
            z-index: 2;
            border-radius: 10px;
            // padding: 10px 0px;
            padding-top: 20px;
            .LocationReviewsLsitShodowR {
                width: 66px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                background: rgb(42, 23, 104);
                background: linear-gradient(270deg, rgba(11, 6, 28, 0.097) 2%, rgba(255, 255, 255, 0) 100%);
                z-index: 2;
            }
            .LocationReviewsLsitUnder {
                display: flex;
                overflow-y: hidden;
                padding-left: 10px;

                .LocationReviewsBox {
                    animation: autoscroll 10s linear infinite;
                    display: flex;
                    flex-direction: column;
                    min-width: 500px;
                    background-color: white;
                    margin-bottom: 25px;
                    border-radius: 8px;
                    margin-right: 30px;
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                    .LocationReviewsBoxLeft {
                        width: 100%;
                        padding-right: 20px;
                        padding: 20px 20px 20px 20px;
                        span {
                            img {
                                width: 30px;
                                margin-right: 10px;
                            }
                        }
                        h2 {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            font-size: 23px;
                            font-weight: 600;
                            color: #2a1768;
                            text-align: start;
                            margin-top: 15px;
                            img {
                                width: 40px;
                                margin-right: 10px;
                            }
                        }
                        h3 {
                            width: 100%;
                            font-size: 16px;
                            font-weight: 300;
                            color: #000000;
                            text-align: start;
                            margin-top: 10px;
                        }
                        h4 {
                            width: 100%;
                            font-size: 18px;
                            font-weight: 300;
                            color: #000000;
                            text-align: start;
                            margin-top: 10px;
                        }
                        h5 {
                            border-radius: 5px;
                            width: 100%;
                            font-size: 15px;
                            font-weight: 600;
                            text-align: start;
                            margin-top: 10px;
                            color: #2a1768;
                            a {
                                color: #2a1768;
                                text-decoration: underline;
                            }
                        }
                        .LocationReviewsBoxLeftBox {
                            margin-top: 20px;
                            width: 100%;
                            // border: 1px solid #dcdcdc80;
                            border-radius: 5px;
                            // padding: 20px;
                            .LocationReviewsBoxLeftBoxBox {
                                // border-bottom: 1px solid #dcdcdc80;
                                padding-bottom: 10px;
                                h5 {
                                    font-size: 15px;
                                    font-weight: 600;
                                    text-align: start;
                                    margin-top: 10px;
                                    color: #000000;
                                }
                                h6 {
                                    font-size: 15px;
                                    font-weight: 400;
                                    text-align: start;
                                    margin-top: 5px;
                                    color: #000000;
                                    a {
                                        color: black;
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .LocationReviewsLsitUnder:hover {
                .LocationReviewsBox {
                    animation-play-state: paused;
                }
            }
        }
    }
    // HomePageGoogleLocation
    .HomePageGoogleLocation {
        display: flex;
        padding: 20px 5% 20px 5%;
        background-color: #eceef6;
        align-items: center;
        height: 500px;
        .HomePageGoogleLocationLeft {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width: 50%;
            height: 100%;
            padding-right: 20px;
            h1 {
                font-size: 30px;
                font-weight: 700;
                color: #2a1768;
                text-align: start;
            }
            h2 {
                text-align: start;
                font-size: 28px;
                font-weight: 400;
                color: #2a1768;
                margin-top: 15px;
                margin-bottom: 8px;
                text-transform: capitalize;
                b {
                    color: #da291c;
                }
                span {
                    font-weight: 600;
                }
            }
            h3 {
                text-align: start;
                font-size: 23px;
                font-weight: 400;
                color: #09031f;
                margin-top: 0px;
                margin-bottom: 8px;
                text-transform: capitalize;
                b {
                    color: #da291c;
                }
            }
            .HomePageGoogleLocationLeftBox {
                margin-top: 10px;
                h4 {
                    font-size: 20px;
                    font-weight: 600;
                    color: #2a1768;
                    text-align: start;
                }
                h5 {
                    font-size: 20px;
                    font-weight: 500;
                    color: #09031f;
                    text-align: start;
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
                a {
                    font-size: 20px;
                    font-weight: 400;
                    color: #09031f;
                    text-align: start;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
        .HomePageGoogleLocationRight {
            position: relative;
            width: 50%;
            height: 100%;
            padding: 10px;
            margin-top: -20px;
            .HomePageGoogleLocationRightBorder {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                background-color: rgba(59, 59, 123, 0);
                border: 2px solid #eceef6;
                border-top: 68px solid #eceef6;
                z-index: 1;
            }
            iframe {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                z-index: 0;
            }
        }
    }
    // MainBanner8
    .MainBanner8 {
        background-color: #2a1768;
        display: flex;
        .MainBanner8Left {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            padding: 50px 5%;
            width: 50%;
            h1 {
                font-size: 22px;
                font-weight: 600;
                color: white;
            }
            h2 {
                font-size: 35px;
                font-weight: 600;
                color: #eceef6;
                margin-top: 20px;
            }
            h3 {
                font-size: 19px;
                font-weight: 500;
                color: #eceef6;
                margin-top: 20px;
                margin-bottom: 30px;
            }
            a {
                align-items: center;
                justify-content: space-between;
                font-size: 32px;
                font-weight: 700;
                color: #da291c;
                cursor: pointer;
                border-radius: 3px;
                cursor: pointer;
                margin-top: 20px;
            }
            a:hover {
                text-decoration: underline;
            }
            a:last-child {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 300px;
                background-color: #eceef6;
                color: #2a1768;
                font-size: 25px;
                padding: 18px 0px;
                font-weight: 500;

                img {
                    height: 25px;
                    width: 25px;
                    margin-left: 10px;
                    filter: invert(0);
                }
            }
        }
        .MainBanner8Right {
            padding: 40px 5%;
            width: 50%;
            .ContactFirstBody {
                display: flex;
                width: 100%;
                margin-top: 40px;
                height: auto;
                gap: 30px;

                form {
                    display: flex;
                    align-items: start;
                    justify-content: start;
                    flex-direction: column;
                    width: 100%;
                    border-radius: 0px 0px 8px 8px;
                    border-top: 3px solid #eceef6;
                    padding: 20px 30px;
                    background-color: white;

                    h4 {
                        font-size: 25px;
                        font-weight: 600;
                        color: #2a1768;
                        margin-top: 15px;
                    }
                    h5 {
                        font-size: 15px;
                        font-weight: 400;
                        color: #606060;
                        margin-top: 10px;
                        margin-bottom: 20px;
                    }
                    h2 {
                        font-size: 18px;
                        font-weight: 500;
                        color: #2a1768;
                        margin-top: 0px;
                    }
                    .ContactFirsthhh {
                        display: flex;
                        gap: 20px;
                        width: 100%;

                        .ContactFirsthhhBox:nth-child(1) {
                            width: 100%;
                        }
                    }
                    input,
                    select,
                    textarea {
                        width: 100%;
                        height: 50px;
                        background: none;
                        color: #727272;
                        outline: none;
                        border: none;
                        border: 2px solid #d4d4d4;
                        border-radius: 5px;
                        margin-bottom: 10px;
                        padding: 10px;
                        text-align: left;
                        font-size: 15px;
                        font-weight: 300;
                        margin-top: 5px;
                    }
                    input:focus,
                    select:focus,
                    textarea:focus {
                        border: 2px solid #9b9b9b;
                    }
                    input:hover,
                    select:hover,
                    textarea:hover {
                        border: 2px solid #9b9b9b;
                    }
                    .Message {
                        height: 120px;
                    }
                    input::placeholder,
                    textarea::placeholder {
                        color: #002f68;
                        font-size: 15px;
                    }
                    input:focus::placeholder {
                        color: transparent;
                    }
                    span {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        input[type="submit"],
                        button {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: #2a1768;
                            color: #ffffff;
                            border-radius: 5px;
                            padding: 10px 20px;
                            margin-top: 20px;
                            outline: unset;
                            border: unset;
                            cursor: pointer;
                            border-bottom: unset;
                            font-size: 20px;
                            font-weight: 600;
                            width: 100%;
                        }
                    }
                    button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #2a1768;
                        color: #ffffff;
                        border-radius: 5px;
                        padding: 10px 20px;
                        margin-top: 20px;
                        outline: unset;
                        border: unset;
                        cursor: pointer;
                        border-bottom: unset;
                        font-size: 25px;
                        font-weight: 600;
                        width: 100%;
                    }
                }
            }
        }
    }
    // MainBanner4
    .MainBanner4 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        min-height: 50vh;
        min-width: 100vw;
        background-color: #eceef6;
        padding: 80px 5% 80px 5%;
        flex-direction: column;
        .MainBanner4Faq {
        }

        .MainBanner4Right {
            width: 100%;
            h1 {
                font-size: 45px;
                font-weight: 600;
                color: #2a1768;
            }
            h2 {
                font-size: 17px;
                font-weight: 400;
                color: #0c0620;
                margin-top: 15px;
            }
            .MainBafnner4RightBenefits {
                margin-top: 30px;
                display: flex;
                flex-wrap: wrap;
                background-color: white;
                padding: 20px 20px;
                border-radius: 5px;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                gap: 20px;
                border-radius: 3px;
                a {
                    background-color: #0156ff0e;
                    padding: 5px 7px;
                    font-size: 18px;
                    font-weight: 400;
                    color: rgb(61, 61, 61);
                }
                a:hover {
                    background-color: #0156ff15;
                }
            }
        }
    }
    // HomePageGoogleFooter
    .HomePageGoogleFooter {
        .HomePageGoogleFooterTop {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 60px 5% 0px 5%;
            margin-bottom: -40px;
            .HomePageGoogleFooterTopTitle {
                display: flex;
                align-items: center;
                justify-content: center;
                // justify-content: space-between;
                h1 {
                    font-size: 30px;
                    font-weight: 600;
                    color: #2a1768;
                    text-align: center;
                    font-style: oblique 50deg;
                    margin: 0px 20px;
                }
                img {
                    width: 40px;
                }
            }
            .HomePageGoogleFooterTopImg {
                img {
                    width: 100px;
                    margin-top: 20px;
                }
            }
        }

        .HomePageGoogleFooterBottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 60px 5%;
            background-color: #eceef6;

            .HomePageGoogleFooterBottomTop {
                padding-right: 50px;
                width: 50%;
                img {
                    width: 220px;
                    height: 100px;
                }
                p {
                    margin-top: 20px;
                    color: #000000;
                }
                .SiteMapDiv {
                    h1 {
                        margin-top: 20px;
                        color: #000000;
                        font-size: 20px;
                        font-weight: 500;
                        margin-bottom: 10px;
                    }
                    a {
                        font-size: 20px;
                        color: #000000;
                        text-decoration: underline;
                        margin-right: 10px;
                    }
                }
            }
            .HomePageGoogleFooterMiddle {
                width: 20%;
                .HomePageGoogleLocationLeftBox {
                    margin-top: 10px;
                    h4 {
                        font-size: 20px;
                        font-weight: 600;
                        color: #2a1768;
                        text-align: start;
                    }
                    h5 {
                        font-size: 18px;
                        font-weight: 500;
                        color: #09031f;
                        text-align: start;
                        margin-top: 5px;
                        margin-bottom: 5px;
                    }
                    a {
                        font-size: 20px;
                        font-weight: 400;
                        color: #09031f;
                        text-align: start;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
            .HomePageGoogleFooterBottomBottom {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 15px;
                    img {
                        width: 30px;
                        margin-right: 15px;
                        // background-color: white;
                        border-radius: 5%;
                        padding: 7px;
                        outline: 2px solid white;
                    }
                    a {
                        font-size: 15px;
                        font-weight: 500;
                        // text-decoration: underline;
                        color: black;
                    }
                }
            }
        }
    }
}
@media (max-width: 890px) {
    .HomePageGoogle {
        .HomePageGoogleChrisms{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #da291c;
            padding: 15px 5%;
            padding-top: 10px;
            h1{
                font-size: 14px;
                font-weight: 400;
                color: white;
            }
        }
        .MainBanner {
            flex-direction: column;
            padding: 118px 5% 260px 5%;
            max-height: unset;
            height: auto;
            min-height: 600px;

            .MainBannerLeft {
                width: 100%;
                height: auto;
                padding-right: 0px;
                padding-left: 0px;
                h1 {
                    font-size: 28px;
                }
                h2 {
                    font-size: 18px;
                }
                h3 {
                    display: flex;
                    align-items: center;
                    font-size: 20px;
                    font-weight: 600;
                    color: #2a1768;
                    margin-top: 10px;
                    img {
                        width: 20px;
                        margin-right: 10px;
                    }
                }
                a {
                    // background-color: white;
                }
            }
            .MainBannerRight {
                width: 100%;
                height: 100%;
                margin-top: 100px;
                background-color: red;
                img {
                    position: absolute;
                    bottom: -220px;
                    // margin-top: 140px;
                    margin-right: 0px;
                    margin-left: 0px;
                    width: 350px;
                    z-index: 3;
                }
                .MainBannerRightBubble1 {
                    right: 190px;
                    bottom: -220px;
                    width: 120px;
                    height: 120px;
                }
                .MainBannerRightBubble2 {
                    right: 50px;
                    bottom: -130px;
                    width: 90px;
                    height: 90px;
                }
            }
        }
        .BookAnAppointmentMainComponentEmergery {
            width: 100%;

            .BookAnAppointmentMainComponentRates {
                padding-top: 0px;
                .GoogleButt {
                    display: flex;
                    flex-direction: column;
                    padding-top: 20px;
                    h6 {
                        font-size: 18px;
                        font-weight: 500;
                        color: white;
                        padding-bottom: 0px;
                    }
                }
                .BookAnAppointmentMainComponentRatesImages {
                    display: flex;
                    width: 100%;
                    // flex-direction: column;
                    overflow: auto;
                    padding-bottom: 10px;
                    img {
                        width: auto;
                        height: 60px;
                        object-fit: cover;
                        margin-right: 20px;
                        margin-top: 20px;
                    }
                }
                h1 {
                    margin-top: 10px;
                }
                h3 {
                    font-size: 15px;
                }
            }
            .BookAnAppointmentMainComponentEmergeryBox {
                a {
                    color: #ffffff;
                    font-size: 20px;
                    font-weight: 600;
                    text-decoration: underline;
                }
            }
        }
        .MainBanner2Top2 {
            .MainBanner2TopBox {
                // padding: 20px 5% 20px 5%;
                .MainBanner2TopBoxMain {
                    padding: 50px 5%;

                    h2 {
                        text-align: start;
                        font-size: 28px;
                    }
                    .MainBanner2TopBoxMenu {
                        flex-direction: column;
                        .MainBanner2TopBoxBox {
                            width: 100%;
                            height: auto;
                            justify-content: start;
                            padding: 10px 10px;
                            margin-bottom: 10px;
                            min-height: 200px;

                            img {
                                width: 80px;
                            }
                        }
                    }
                    .MainBanner2TopBoxMainChat {
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        padding: 0px 20px 20px 20px;
                        margin-top: 70px;
                        img {
                            // border: 2px solid #2a1768;
                            // border-radius: 50%;
                            // padding: 5px;
                            margin-top: -70px;
                            width: 110px;
                        }
                        .MainBanner2TopBoxMainChatText {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding-left: 0px;
                            h4,
                            h3 {
                                // width: 100%;
                                text-align: center;
                            }
                            h4 {
                                font-size: 21px;
                                margin-top: 15px;
                            }
                            h3 {
                                font-size: 16px;
                            }
                            h5 {
                                border-left: 0px;
                                text-align: center;
                                font-size: 16px;

                                a {
                                    margin-top: 20px;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
        .MainBanner2 {
            padding: 20px 5%;
            height: auto;

            .MainBanner2TopTop {
                flex-direction: column-reverse;
                height: auto;

                .MainBanner2TopTopRight {
                    width: 100%;
                    h4 {
                        font-size: 40px;
                    }
                    .MainBanner2TopBox {
                        padding-left: 10px;
                        justify-content: start;
                        padding: 2px 0px;
                        h2 {
                            text-align: left;
                            font-size: 20px;
                        }
                        h3 {
                            text-align: left;
                            font-size: 16px;
                        }
                    }
                }
                .MainBanner2TopTopLeft {
                    width: 100%;
                    margin-left: 0px;
                    height: 200px;
                    padding: 0px;
                    margin-top: 30px;
                }
            }
            .MainBanner2TopTopBtn {
                padding: 0px;
                width: 100%;
                flex-direction: column;
                .MainBanner2TopTopBtnLeft {
                    align-items: center;
                    flex-direction: column;
                    padding-right: 0px;
                    width: 100%;
                    padding: 20px;
                    img {
                        margin-right: 0px;
                    }
                    span {
                        align-items: center;
                        h1,
                        h2 {
                            text-align: center;
                        }
                        h1 {
                            font-size: 28px;
                            margin-top: 20px;
                        }
                        h2 {
                            font-size: 15px;
                        }
                    }
                }
                .MainBanner2TopTopBtnRight {
                    align-items: center;
                    padding: 20px;
                    width: 100%;
                    a {
                        width: 100%;
                        font-size: 22px;
                    }
                }
            }
        }
        .MainBanner3 {
            .MainBanner2Bottom {
                // margin-bottom:40px;

                .MainBanner2BottomBox {
                    flex-direction: column;
                    border-radius: 0px;
                    margin-top: -20px;
                    padding: 20px 5% 20px 5%;
                    margin-bottom: 40px;

                    .MainBanner2BottomBoxLeft {
                        padding-right: 0px;
                        width: 100%;
                        h2 {
                            font-size: 30px;
                        }
                    }
                    .MainBanner2BottomBoxRight {
                        width: 100%;
                        margin-top: 10px;
                        img {
                            height: 200px;
                            border-radius: 8px;
                        }
                    }
                }
                .MainBanner2BottomBox:last-child {
                    flex-direction: column;
                    width: 100%;
                    .MainBanner2BottomBoxRight {
                        width: 100%;
                        padding-right: 0px;
                    }
                }
            }
        }
        .MainBanner4 {
            flex-direction: column-reverse;
            padding: 50px 5% 50px 5%;
            .MainBanner4Left {
                width: 100%;
                margin-top: 50px;
                margin-bottom: -180px;
                img {
                    max-width: 150px;
                    min-height: 300px;
                }
                .MainBanner4LeftBubble1 {
                    bottom: 50px;
                    left: 20px;
                }
                .MainBanner4LeftBubble2 {
                    bottom: -50px;
                }
            }
            .MainBanner4Right {
                width: 100%;
                h1 {
                    font-size: 35px;
                }
                .MainBanner4RightBenefits {
                    padding: 20px 10px;
                    a {
                        font-size: 15px;
                    }
                }
            }
        }
        .MainBanner5 {
            padding-top: 0px;
            padding-bottom: 70px;
            .MainBanner5Left {
                width: 100%;
                padding-right: 0px;

                h1 {
                    font-size: 25px;
                }
                .MainBanner5LeftBox {
                    .MainBanner5LeftBoxRigth {
                        h4 {
                            font-size: 16px;
                        }
                    }
                }
                a {
                    font-size: 20px;
                    padding: 15px 15px;
                }
            }
            .MainBanner5Right {
                display: none;
            }
        }
        .MainBanner6 {
            height: auto;
            position: relative;
            padding-bottom: 160px;
            .MainBanner6Left {
                width: 100%;
                padding-right: 0px;
                z-index: 1;
                h2 {
                    font-size: 30px;
                }
                h3 {
                    padding-right: 0%;
                }
                .MainBanner4FaqMore {
                    .row-title-text,
                    .styles_row-title-text__1MuhU {
                        font-weight: 600;
                        font-size: 16px;
                    }
                    .faq-row,
                    .styles_faq-row__2YF3c {
                        border-bottom: 1px solid #2a1768;
                    }
                }
            }
            .MainBanner6Right {
                position: absolute;
                bottom: 0px;
                right: 10px;
                align-items: flex-end;

                img {
                    width: 100%;
                    margin-left: -80px;
                }
            }
        }
        .MainBanner7 {
            width: 100%;
            padding: 20px 5%;
            h1 {
                font-size: 25px;
            }

            .LocationReviewsLsit {
                .LocationReviewsLsitUnder {
                    .LocationReviewsBox {
                        min-width: unset;
                        min-width: 300px;
                        margin-right: 20px;

                        .LocationReviewsBoxHeader {
                            padding: 15px;

                            .LocationReviewsBoxHeaderBox {
                                h1 {
                                    font-size: 12px;
                                    font-weight: 500;
                                    color: rgb(255, 255, 255);
                                }
                                h2 {
                                    font-size: 10px;
                                    font-weight: 200;
                                    color: rgb(255, 255, 255);
                                    margin-top: 5px;
                                }
                            }
                        }
                        .LocationReviewsBoxLeft {
                            width: auto;
                            padding-right: 0px;
                            padding: 15px;
                            span {
                                img {
                                    width: 20px;
                                    margin-right: 10px;
                                }
                            }
                            h3 {
                                width: 100%;
                                font-size: 9px;
                                font-weight: 300;
                                color: #000000;
                                text-align: start;
                                margin-top: 0px;
                            }
                            h2 {
                                width: 100%;
                                font-size: 15px;
                                font-weight: 600;
                                color: #2a1768;
                                text-align: start;
                                margin-top: 5px;
                            }
                            h4 {
                                width: 100%;
                                font-size: 13px;
                                font-weight: 400;
                                color: #000000;
                                text-align: start;
                                margin-top: 10px;
                            }
                            h5 {
                                border-radius: 5px;
                                width: 100%;
                                font-size: 12px;
                                font-weight: 600;
                                text-align: start;
                                margin-top: 10px;
                                color: #2a1768;
                                a {
                                    color: #2a1768;
                                    text-decoration: underline;
                                }
                            }
                            .LocationReviewsBoxLeftBox {
                                margin-top: 10px;
                                width: 100%;
                                // border: 1px solid #dcdcdc80;
                                border-radius: 5px;
                                .LocationReviewsBoxLeftBoxBox {
                                    // border-bottom: 1px solid #dcdcdc80;
                                    h5 {
                                        font-size: 14px;
                                        font-weight: 600;
                                        text-align: start;
                                        margin-top: 10px;
                                        color: #000000;
                                    }
                                    h6 {
                                        font-size: 13px;
                                        font-weight: 400;
                                        text-align: start;
                                        margin-top: 4px;
                                        color: #000000;
                                        a {
                                            color: black;
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .HomePageGoogleLocation {
            flex-direction: column;
            height: auto;
            .HomePageGoogleLocationLeft {
                padding-right: 0px;
                width: 100%;
                h2 {
                    font-size: 20px;
                }
                .HomePageGoogleLocationLeftBox {
                    h5 {
                        font-size: 18px;
                    }
                }
            }
            .HomePageGoogleLocationRight {
                width: 100%;
                min-height: 400px;
                margin-top: 0px;
            }
        }
        .MainBanner8 {
            flex-direction: column;
            .MainBanner8Left {
                width: 100%;
                padding: 40px 5% 20px 5%;
                h2 {
                    margin-top: 10px;
                }
                h3 {
                    margin-bottom: 10px;
                }
            }
            .MainBanner8Right {
                padding: 0px 5% 20px 5%;
                width: 100%;
                .ContactFirstBody {
                    margin-top: 20px;
                    border-top: 3px solid #2a1768;
                    form {
                        border-radius: 0px 0px 8px 8px;

                        h5 {
                            margin-bottom: 20px;
                        }
                        h2 {
                            margin-top: 0px;
                        }
                    }
                }
            }
        }
        .HomePageGoogleFooter {
            .HomePageGoogleFooterTop {
                .HomePageGoogleFooterTopTitle {
                    h1 {
                        font-size: 28px;
                        margin: 0px 6px;
                    }
                }
            }
            .HomePageGoogleFooterBottom {
                flex-direction: column-reverse;
                .HomePageGoogleFooterBottomTop {
                    padding-right: 0px;
                    margin-top: 50px;
                    width: 100%;
                    p {
                        font-size: 12px;
                    }
                }
                .HomePageGoogleFooterMiddle {
                    width: 100%;
                    margin-top: 40px;
                }
                .HomePageGoogleFooterBottomBottom {
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    span {
                        img {
                            width: 20px;
                            padding: 4px;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 1700px) {
    .HomePageGoogle {
        .MainBanner {
            padding-right: 13%;
            padding-left: 13%;
            .MainBannerLeft {
                padding-left: 0px;
            }
            .MainBannerRight {
                // display: flex;
            }
        }
        .BookAnAppointmentMainComponentEmergery {
            .BookAnAppointmentMainComponentRates {
                width: calc(100% - 26%);
                margin-right: 13%;
                margin-left: 13%;
            }
        }
        .MainBanner2Top2 {
            .MainBanner2TopBox {
                .MainBanner2TopBoxMain {
                    padding: 50px 13%;
                }
            }
        }
        .MainBannerReviews {
            padding-right: 13%;
            padding-left: 13%;
        }
        .MainBanner2 {
            padding-right: 13%;
            padding-left: 13%;
        }
        .MainBanner3 {
            padding-right: 13%;
            padding-left: 13%;
        }
        .MainBanner4 {
            padding-right: 13%;
            padding-left: 13%;
        }
        .MainBanner5 {
            padding-right: 13%;
            padding-left: 13%;
        }
        .MainBanner6 {
            padding-right: 13%;
            padding-left: 13%;
        }
        .MainBanner7 {
            padding-right: 13%;
            padding-left: 13%;
        }
        .HomePageGoogleLocation {
            padding-right: 13%;
            padding-left: 13%;
        }
        .MainBanner8 {
            padding-right: 13%;
            padding-left: 13%;
        }
        .HomePageGoogleFooter {
            .HomePageGoogleFooterTop,
            .HomePageGoogleFooterBottom {
                padding-right: 13%;
                padding-left: 13%;
            }
        }
    }
}
@keyframes autoscroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}
